import * as React from 'react'
import { Trans, t } from '@lingui/macro'
import classnames from 'classnames'

import { useLocalisation, Language } from '../../i18n'
import { useMediaQuery } from '../../../lib/use-media-query'
import { Content } from '../content'
import { Shortcuts, useShortcut } from '../shortcuts'
import { questionMark } from '../../keycodes'

import { CaretDown, ChevronDown, FacebookSquare, Globe, Instagram, Youtube, Linkedin, Tiktok } from '../icon'
import Logo from '../logo'
import { Link } from '../link'

import css from './styles.css'

import appleBadge from './images/app-store-badge.svg'
import googleBadge from './images/google-play-badge.png'

const appleURL = 'https://itunes.apple.com/app/apple-store/id1036449551?pt=117888292&ct=discogs-app-page&mt=8'
const googleURL = 'https://play.google.com/store/apps/details?id=com.discogs.app&referrer=utm_source%3Ddiscogs-app-page'

export function Footer(): React.ReactElement {
    const { i18n } = useLocalisation()
    const [shortcuts, setShortcuts] = React.useState(false)
    const mobile = useMediaQuery(`(max-width: 650px)`)

    function showShortcuts(): void {
        setTimeout(() => setShortcuts(true), 10)
    }
    function hideShortcuts(): void {
        setShortcuts(false)
    }

    useShortcut([questionMark], 'action', <Trans>Show Shortcut Help</Trans>, function () {
        setShortcuts((open: boolean): boolean => !open)
    })

    function handleManagePreferencesClick() {
        const consentScript = document.createElement('script')
        consentScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        consentScript.dataset.language = 'en'
        consentScript.dataset.domainScript = '05dc22ad-af2f-4fcc-865c-860f6ce05f91'
        document.head.appendChild(consentScript)

        const wrapperScript = document.createElement('script')
        wrapperScript.textContent = 'function OptanonWrapper() {}'
        document.head.appendChild(wrapperScript)
    }

    return (
        <footer id='site_footer_wrap' className={css.footer}>
            <Shortcuts open={shortcuts} onClose={hideShortcuts} />
            <Content>
                <nav className={css.columns} aria-label={i18n._(t`Footer`)}>
                    <Ul title={<Trans>About Discogs</Trans>} mobile={mobile}>
                        <li>
                            <Link href='/about'>
                                <Trans>About Discogs</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/digs'>Discogs Digs</Link>
                        </li>
                        <li>
                            <Link href='/careers'>
                                <Trans>Careers</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='/developers'>
                                <Trans>API</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://status.discogs.com'>
                                <Trans>Status</Trans>
                            </Link>
                        </li>
                    </Ul>
                    <Ul title={<Trans>Help Is Here</Trans>} mobile={mobile}>
                        <li>
                            <Link href='https://support.discogs.com'>
                                <Trans>Help &amp; Support</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='/forum'>
                                <Trans>Forum</Trans>
                            </Link>
                        </li>
                        <li>
                            <button type='button' onClick={showShortcuts}>
                                <Trans>Keyboard Shortcuts</Trans>
                            </button>
                        </li>
                        <li>
                            <Link href='https://support.discogs.com/hc/articles/360004016474-Overview-of-Submission-Guidelines-for-Releases'>
                                <Trans>Database Guidelines</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/shipping'>
                                <Trans>Discogs Shipping</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/selling'>
                                <Trans>Start Selling</Trans>
                            </Link>
                        </li>
                    </Ul>
                    <Ul title={<Trans>Join In</Trans>} mobile={mobile}>
                        <li>
                            <Link href='https://www.discogs.com/getstarted/'>
                                <Trans>Get Started</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/help/doc/contributing'>
                                <Trans>Contribute</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/release/add'>
                                <Trans>Add Release</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/stats/contributors'>
                                <Trans>Contributor List</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/group/thread/963554'>
                                <Trans>Help Translate</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://wantlister.discogs.com'>
                                <Trans>Visit Wantlister</Trans>
                            </Link>
                        </li>
                        <li>
                            <Link href='https://www.discogs.com/company/partnership-and-advertising-inquiries/'>
                                <Trans>Advertise With Us</Trans>
                            </Link>
                        </li>
                    </Ul>
                    <ul className={classnames(css.section)}>
                        <li>
                            <h2>
                                <Trans>Follow Us</Trans>
                            </h2>
                        </li>
                        <li>
                            <ul className={classnames(css.social)}>
                                <li>
                                    <Link
                                        href='https://www.facebook.com/discogs'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        title='opens in a new window'
                                    >
                                        <FacebookSquare aria-label='Discogs on Facebook' />{' '}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        href='https://www.instagram.com/discogs/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        title='opens in a new window'
                                    >
                                        <Instagram aria-label='Discogs on Instagram' />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        href='https://www.youtube.com/channel/UCIJC0XjREJDO7ZTb9Nx7zfw'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        title='opens in a new window'
                                    >
                                        <Youtube aria-label='Discogs on YouTube' />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        href='https://www.tiktok.com/@discogs'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        title='opens in a new window'
                                    >
                                        <Tiktok aria-label='Discogs on TikTok' />
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        href='https://www.linkedin.com/company/discogs/'
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        title='opens in a new window'
                                    >
                                        <Linkedin aria-label='Discogs on LinkedIn' />
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <ul className={classnames(css.apps)}>
                                <li>
                                    <h2>
                                        <Trans>On The Go</Trans>
                                    </h2>
                                </li>
                                <li>
                                    <Link href={appleURL}>
                                        <img alt={i18n._(t`Download now from the App Store`)} src={appleBadge} />
                                    </Link>
                                </li>
                                <li>
                                    <Link href={googleURL}>
                                        <img alt={i18n._(t`Download now from Google Play`)} src={googleBadge} />
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </Content>
            <div className={css.bottom}>
                <Content className={css.inner}>
                    <nav className={css.columns} aria-label={i18n._(t`Legal`)}>
                        <Link href='/' title='Discogs Home'>
                            <Logo className={css.logo} alt='Discogs' />
                        </Link>
                        &copy; {new Date().getFullYear()} Discogs&reg;
                        <ul>
                            <li>
                                <button
                                    type='button'
                                    className={classnames('ot-sdk-show-settings', css.cookies)}
                                    id='ot-sdk-btn'
                                    onClick={handleManagePreferencesClick}
                                >
                                    <Trans>Manage Preferences</Trans>
                                </button>
                            </li>
                            <li>
                                <Link href='https://www.discogs.com/help/doc/cookie-policy'>
                                    <Trans>Cookie Policy</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link href='https://www.discogs.com/help/doc/terms-of-service'>
                                    <Trans>Terms of Service</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link href='https://www.discogs.com/help/doc/privacy-policy'>
                                    <Trans>Privacy Policy</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link href='https://support.discogs.com/hc/articles/360039846494-California-Privacy-Notice'>
                                    <Trans>California Privacy Notice</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link href='https://support.discogs.com/hc/en-us/articles/360017786278-Accessibility-Statement'>
                                    <Trans>Accessibility Statement</Trans>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <LanguageSelect />
                </Content>
            </div>
        </footer>
    )
}

type UlProps = {
    title: React.ReactNode
    children: React.ReactNode
    mobile: boolean
}

function Ul(props: UlProps): React.ReactElement {
    const { children, title, mobile } = props
    const [open, setOpen] = React.useState(false)
    const desktop = !mobile
    function toggle(): void {
        setOpen((x: boolean): boolean => !x)
    }

    return (
        <ul className={classnames(css.section, open && css.open)}>
            <li>
                <h2>
                    <button onClick={toggle} aria-expanded={desktop ? true : open} disabled={desktop}>
                        {title}
                        <ChevronDown aria-hidden='true' />
                    </button>
                </h2>
                <ul className={css.content}>{children}</ul>
            </li>
        </ul>
    )
}

const languages = {
    de: 'Deutsch',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
    ja: ' 日本語 ',
    ko: ' 한국어 ',
    'pt-BR': 'Português (Brasil)',
    ru: ' Русский ',
}

function LanguageSelect(): React.ReactElement {
    const { language, changeLanguage } = useLocalisation()

    function handleLanguageChange(evt: React.ChangeEvent<HTMLSelectElement>): void {
        changeLanguage(evt.target.value as Language)
    }

    return (
        <label className={css.i18n} htmlFor='select-lang'>
            <Globe className={css.globe} aria-hidden='true' /> {languages[language]}
            <CaretDown className={css.caret} aria-hidden='true' />
            <select value={language} id='select-lang' onChange={handleLanguageChange}>
                <option value='de' lang='de'>
                    Deutsch
                </option>
                <option value='en' lang='en'>
                    English
                </option>
                <option value='es' lang='es'>
                    Espa&ntilde;ol
                </option>
                <option value='fr' lang='fr'>
                    Fran&ccedil;ais
                </option>
                <option value='it' lang='it'>
                    Italiano
                </option>
                <option value='ja' lang='ja'>
                    {' 日本語 '}
                </option>
                <option value='ko' lang='ko'>
                    {' 한국어 '}
                </option>
                <option value='pt-BR' lang='pt-BR'>
                    Portugu&ecirc;s (Brasil)
                </option>
                <option value='ru' lang='ru'>
                    {' Русский '}
                </option>
            </select>
        </label>
    )
}
