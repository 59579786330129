import { ApolloClient, InMemoryCache, createHttpLink, NormalizedCacheObject, FetchResult } from '@apollo/client'
import { onError as createErrorLink, ErrorResponse } from '@apollo/client/link/error'
import { Observable } from '@apollo/client/utilities'
import { createPersistedQueryLink, ErrorResponse as PErrorResponse } from '@apollo/client/link/persisted-queries'
import { generateHash } from '../lib/generate-hash'

import dsdata from '../discogs-data'
import { config } from '../config'

import fragments from '../api/fragments.json'
import hashes from '../api/queries/client.json'
import { typePolicies } from '../policies'

type Options = {
    onError?: (err: Error) => void
}

// The max lenght of the urls we're willing to send using GET.
const URL_MAX_LENGTH = 1800

export function apollo(options: Options): ApolloClient<NormalizedCacheObject> {
    const { data } = dsdata
    const { onError } = options

    const cache = new InMemoryCache({
        possibleTypes: fragments.possibleTypes,
        typePolicies,
    })
    cache.restore(data)

    const errors = createErrorLink(function (ctx: ErrorResponse): Observable<FetchResult> {
        if (ctx.networkError && onError) {
            onError(ctx.networkError)
        }

        return ctx.forward(ctx.operation)
    })

    const http = createHttpLink({
        uri: `${config.API_URL ?? ''}/service/catalog/api/graphql`,
        credentials: 'include',
        fetch(url: RequestInfo | URL, init?: RequestInit<CfProperties<unknown>>): Promise<Response> {
            if (process.isDevWatching) {
                const impersonate = new URL(window.location.toString()).searchParams.get('impersonate')
                if (impersonate && init) {
                    init.headers = init.headers ?? {}
                    // @ts-expect-error
                    init.headers['Discogs-Authenticated-User-Id'] = impersonate
                }
            }
            if (typeof url === 'string') {
                if (url.length > URL_MAX_LENGTH) {
                    // If the query and variables become big, the url might not
                    // be within spec. In this case we can fall back to using a POST query.
                    const [path, search] = url.split('?')
                    const q = new URLSearchParams(search)

                    const operationName = q.get('operationName')
                    const variables = q.get('variables')
                    const extensions = q.get('extensions')
                    const query = q.get('query')

                    const body = JSON.stringify({
                        operationName,
                        variables: variables ? JSON.parse(variables) : undefined,
                        extensions: extensions ? JSON.parse(extensions) : undefined,
                        query: query ? JSON.parse(query) : undefined,
                    })

                    return fetch(path, {
                        ...init,
                        method: 'POST',
                        body,
                    })
                }

                if (
                    init &&
                    init.method === 'POST' &&
                    config.NODE_ENV !== 'production' &&
                    typeof init.body === 'string'
                ) {
                    const body = JSON.parse(init.body)
                    const u = new URL(url, window.location.toString())
                    u.searchParams.set('_op', body.operationName)
                    return fetch(u.toString(), init)
                }
            }

            return fetch(url as RequestInfo, init)
        },
    })

    const persistent = createPersistedQueryLink({
        useGETForHashedQueries: !process.isDevWatching,
        generateHash: generateHash(hashes),
        disable(resp: PErrorResponse): boolean {
            // Disable persisted queries if the server does not accept them
            if (resp.graphQLErrors?.some((err) => err.message === 'PersistedQueryNotSupported')) {
                return true
            }

            return false
        },
    })
    return new ApolloClient({ cache, link: errors.concat(persistent.concat(http)), name: 'release-page-client' })
}
