/* eslint-disable arrow-body-style */
import { t } from '@lingui/macro'
import { ApolloCache } from '@apollo/client'

import {
    useSetViewerLanguageMutation as useSetViewerLanguageMutation_,
    Language as UserLanguage,
    SetViewerLanguageMutation,
    User,
} from '../api/types'
import { useErrorHandler } from './components/errors'
import { Modifier } from '@apollo/client/cache'

export function useSetViewerLanguageMutation(): (language: UserLanguage) => Promise<void> {
    const [setLanguage] = useSetViewerLanguageMutation_()
    const onError = useErrorHandler(t`Could not change your language preference`)

    return async function (language: UserLanguage): Promise<void> {
        await setLanguage({
            variables: {
                language,
            },
            optimisticResponse: {
                updateViewerProfile: {
                    __typename: 'UpdateProfilePayload',
                    ok: true,
                    user: {
                        // @ts-expect-error: ts does not know about __typename
                        __typename: 'User',
                        language,
                    },
                },
            },
            update(cache: ApolloCache<SetViewerLanguageMutation>): void {
                cache.modify({
                    id: cache.identify({ __typename: 'Query' }),
                    fields: {
                        viewer: ((existing: User | null): User | null => {
                            if (!existing) {
                                return existing
                            }

                            return {
                                ...existing,
                                language,
                            }
                            // Apollo Client (<= 3.x) has strict Modifier type requirements that
                            // can be difficult to satisfy when working with complex nested types.
                            // This type assertion works around those type mismatches.
                            // TODO: Consider refactoring to use a helper function for better type safety.
                        }) as unknown as Modifier<boolean>,
                    },
                })
            },
        }).catch(onError)
    }
}
